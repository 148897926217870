import React, { Component } from "react";

class Footer extends Component {
  render() {

    var socialData = [
      {
        "name": "GitHub",
        "url": "https://github.com/gillet-thomas",
        "class": "fab fa-github"
      },
      {
        "name": "LinkedIn",
        "url": "https://www.linkedin.com/in/tgillet/",
        "class": "fab fa-linkedin"
      },
      {
        "name": "Email",
        "url": "mailto:contact@thomasgillet.com",
        "class": "far fa-envelope"
      }
      // {
      //   "name": "Phone",
      //   "url": "tel:",
      //   "class": "fas fa-phone-alt"
      // }
    ]

    var networks = socialData.map(function (network) {
      return (
        <span key={network.name} className="m-4">
          <a
            href={network.url}
            target="_blank"
            rel="noopener noreferrer"
            title={network.name}
          >
            <i className={network.class}></i>
          </a>
        </span>
      );
    });

    return (
      <footer>
        <div className="col-md-12">
          <div className="social-links">
            {networks}
          </div>
          <div className="copyright py-4 text-center">
            <div className="container">
              <small>
                Copyright &copy;{" 2024 "}Thomas Gillet
              </small>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React, { Component } from "react";
import Typical from "react-typical";

class Header extends Component {
    titles = [];

    render() {
        if (this.props.basic_info) {
            var name = "Thomas Gillet";
            this.titles = this.props.basic_info.titles.map((x) => [x.toUpperCase(), 1500]).flat();
        }

        const HeaderTitleTypeAnimation = React.memo(
            () => {
                return <Typical className="title-styles" steps={this.titles} loop={100} />;
            },
            (props, prevProp) => true
        );

        return (
            <header id="home" style={{ height: window.innerHeight - 140, display: "block" }}>
                <div className="row aligner" style={{ height: "100%" }}>
                    <div className="col-md-12">
                        <div>
                            <span className="iconify header-icon text-white" data-icon="la:laptop-code" data-inline="false"></span>
                            <br />
                            <h1 className="mb-0 text-white">
                                <Typical steps={[name]} wrapper="p" />
                            </h1>
                            <div className="title-container text-white">
                                <HeaderTitleTypeAnimation />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;

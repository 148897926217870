import React, { Component } from "react";
import $ from "jquery";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Publications from "./components/Publications";
// import Switch from "react-switch";

class App extends Component {
    constructor() {
        super();
        this.state = {
            localizedData: {},
        };
    }

    componentDidMount() {
        this.loadLocalizedData();
    }

    loadLocalizedData() {
        $.ajax({
            url: "dataPrimary.json",
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({ localizedData: data });
                // console.log("resume data", this.state.localizedData);
            }.bind(this),
            error: function (err) {
                console.log(err);
            },
        });
    }

    render() {
        return (
            <div>
                <Header basic_info={this.state.localizedData.basic_info} />
                <About basic_info={this.state.localizedData.basic_info} />
                <Projects data={this.state.localizedData} />
                <Skills basic_info={this.state.localizedData.basic_info} />
                <Publications data={this.state.localizedData} />
                <Experience data={this.state.localizedData} />
                <Footer basic_info={this.state.localizedData.basic_info} sharedData={this.state.sharedData} />
            </div>
        );
    }
}

export default App;

import React, { Component } from "react";
import { ProgressBar, Card, Row, Col, Container } from "react-bootstrap";

import { CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VisibilitySensor from "react-visibility-sensor";

class Skills extends Component {
  get(option1, option2) {
    return document.documentElement.lang === "en" ? option1 : option2;
  }
  render() {
    if (this.props.basic_info) {
      var sectionName = this.props.basic_info.section_name.skills;
    }

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-black">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 pt-0 mt-400 row" style={{ margin: 0 }}>
            <Container style={{ padding: "10px" }}>
              <Row>
                <Col lg={6} sm={12}>
                  <Card className="skillsCard">
                    <p>
                      <strong>Frontend</strong>: JS, VueJS, ReactJS, ReactNative, HTML, CSS, jQuery
                    </p>
                    <ProgressBar now={80} style={{ marginBottom: "20px" }} />
                    <p>
                      <strong>Backend</strong>: SQL, NodeJS, Nginx, MongoDB, Redis, Laravel
                    </p>
                    <ProgressBar now={60} />
                  </Card>
                </Col>
                <Col lg={6} sm={12}>
                  <Card className="skillsCard">
                    Java, C#, Visual Basic.net
                    <ProgressBar now={80} style={{ marginBottom: "20px" }} />
                    Java EE, Spring, SpringBoot
                    <ProgressBar now={70} />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Card className="skillsCard">
                    <p>
                      <strong>Data science</strong>: Python, OpenCV, NLP, Keras, TF, Scikit-learn, ANN, CNN<br/>
                      Notions in: Data preprocessing, Feature engineering, Hadoop, Spark
                    </p>
                    <ProgressBar now={40} />
                  </Card>
                </Col>
                <Col lg={6} sm={12}>
                  <Card className="skillsCard" id="skillWeb3">
                    <p>
                      <strong>Web3</strong>: Web3js, Solidity, Truffle, Mocha, Infura
                    </p>
                    <ProgressBar now={45} />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <Card className="skillsCard" style={{ padding: "15px 10px 6px 10px" }}>
                    <p>
                      <strong>{this.get("Tools", "Outils")}</strong>: Bash, Git, GitKraken, Docker, Kubernetes, Minikube, WordPress, GulpJS
                    </p>
                  </Card>
                </Col>
                <Col lg={6} sm={12}>
                  <Card className="skillsCard languages" id="skillLanguages">
                    <div style={{ width: 80, height: 80 }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? 100 : 0;
                          return <CircularProgressbar value={percentage} text={this.get("French", "Français")} />;
                        }}
                      </VisibilitySensor>
                    </div>
                    <div style={{ width: 80, height: 80 }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? 90 : 0;
                          return <CircularProgressbar value={percentage} text={this.get("English", "Anglais")} />;
                        }}
                      </VisibilitySensor>
                    </div>
                    <div style={{ width: 80, height: 80 }}>
                      <VisibilitySensor>
                        {({ isVisible }) => {
                          const percentage = isVisible ? 33 : 0;
                          return (
                            <CircularProgressbarWithChildren value={percentage}>
                              <div style={{ fontSize: 12 }}>
                                Luxemb-
                                <br />
                                {this.get("ourgish", "ourgeois")}
                              </div>
                            </CircularProgressbarWithChildren>
                          );
                        }}
                      </VisibilitySensor>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;

import React, { Component } from "react";

class Publications extends Component {
  render() {
    if (this.props.data.publications && this.props.data.basic_info) {
      var sectionName = this.props.data.basic_info.section_name.publications;
      var publications_data = this.props.data.publications.slice(0, 3);
      var publications = publications_data.map(function (publication) {
        return (
          <div className="col-sm-12 col-md-4 col-lg-4" key={publication.title} style={{ cursor: "pointer" }}>
            <a href={publication.url} target="_blank" rel="noopener noreferrer">
              <div class="card">
                <div class="card-body">
                  <span class="main-badge mr-2 mb-3 badge badge-pill">{ publication.date }</span>
                  <h3 class="card-text mb-1">{ publication.title }</h3>
                  <h4 class="card-text publication-journal"> { publication.journal }</h4>
                </div>
                <div class="card-footer">
                  by { publication.authors }
                </div>
              </div>
            </a>
          </div>
        );
      });
    }


    return (
      <section id="publications">
       <div className="col-md-12 mx-auto">
          <div className="col-md-12">
            <h1 className="section-title mb-1">
              <span className="text-black" style={{ textAlign: "center" }}>
                <span className="text-white">{sectionName}</span>
              </span>
            </h1>
          </div>
        </div>
        <div className="col-lg-12 mx-auto">
          <div className="row justify-content-center mx-auto">{publications}</div>
        </div>
      </section>
    );
  }
}

export default Publications;

import React, { Component } from "react";

class Collapse extends Component {
  state = { childHeight: 0 };

  componentDidMount() {
    const childHeightRaw = this.content.clientHeight;
    const childHeight = `${childHeightRaw / 16}rem`;

    this.setState({ childHeight });
  }

  render() {
    const { children, isOpen, projectNumber } = this.props;

    // var height = Math.ceil(projectNumber/3) * 500;
    var height = projectNumber * 500;

    return (
      <div className="hiddenProjects" style={{maxHeight: isOpen ? `${height}px` : 0}}>
        <div ref={content => (this.content = content)}>{children}</div>
      </div>
    );
  }
}

Collapse.defaultProps = {
  isOpen: false
};

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deps: {},
      detailsModalShow: false,
      expendProjects: false
    };
  }

  render() {
    let detailsModalShow = (data) => {
      window.open(data.url, "_blank");
    };

    let expendProjects = () => {
      this.setState({ expendProjects: !this.state.expendProjects });
    };

    if (this.props.data.projects && this.props.data.basic_info) {
      var sectionName = this.props.data.basic_info.section_name.projects;
      var highlights = this.props.data.projects.slice(0, 3);
      var projects = highlights.map(function (projects) {
        return (
          <div className="col-sm-12 col-md-4 col-lg-4" key={projects.title} style={{ cursor: "pointer" }}>
            <span className="portfolio-item d-block">
              <div className="foto">
                <div onClick={() => detailsModalShow(projects)}>
                  <img
                    src={projects.images[0]}
                    alt={projects.title}
                    style={{ marginBottom: 0, paddingBottom: 0, position: "relative" }}
                  />
                  {projects.startDate !== "" && <span className="project-date">{projects.startDate}</span>}
                  <br />
                  <p className="project-title-settings mt-3">{projects.title}</p>
                </div>
              </div>
            </span>
          </div>
        );
      });

      var hidden = this.props.data.projects.slice(3);
      var hiddenProjects = hidden.map(function (projects) {
        return (
          <div className="col-sm-12 col-md-4 col-lg-4" key={projects.title} style={{ cursor: "pointer" }}>
            <span className="portfolio-item d-block">
              <div className="foto">
                <div onClick={() => detailsModalShow(projects)}>
                  <img
                    src={projects.images[0]}
                    alt={projects.title}
                    style={{ marginBottom: 0, paddingBottom: 0, position: "relative" }}
                  />
                  {projects.startDate !== "" && <span className="project-date">{projects.startDate}</span>}
                  <br />
                  <p className="project-title-settings mt-3">{projects.title}</p>
                </div>
              </div>
            </span>
          </div>
        );
      });

    }

    return (
      <section id="portfolio">
        <div className="col-lg-12">
          <h1 className="section-title">
            <span className="text-white">{sectionName}</span>
          </h1>
          <div className="col-lg-12 mx-auto">
            <div className="row justify-content-center mx-auto">{projects}</div>
          </div>

          <button id="expend" onClick={expendProjects} style={{visibility: this.state.expendProjects ? "hidden" : "initial"}}><i className="fas fa-chevron-down"></i></button>
          <Collapse isOpen={this.state.expendProjects} projectNumber={hiddenProjects ? hiddenProjects.length : 0}>
            <div className="col-lg-12 mx-auto">
              <div className="row justify-content-center mx-auto">{this.state.expendProjects && hiddenProjects}</div>
            </div>
          </Collapse>

        </div>
      </section>
    );
  }
}

export default Projects;

import React, { Component } from "react";

class About extends Component {
  render() {
    if (this.props.basic_info) {
      var sectionName = this.props.basic_info.section_name.about;
      var hello = this.props.basic_info.description_header;
      var about = this.props.basic_info.description;
    }

    return (
      <section id="about">
        <div className="col-md-12">
          <h1 className="section-title">
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    src="/images/profile.jpg"
                    alt="Thomas Gillet"
                  />
                  {/* <Icon icon={vueIcon} className="polaroid-icon" />
                  <Icon icon={nodeIcon} className="polaroid-icon" />
                  <Icon icon={javaIcon} className="polaroid-icon" /> */}
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                  >
                    <br />
                    <span className="wave">{hello}</span>
                    <br />
                    <br />
                    {/* {about} */}
                    As a 23-year-old Belgian with a strong passion for IT, I am currently leveraging my skills as a <i>Research and Development Specialist in AI/Machine Learning for Medical Image Computing</i>. This position allows me to apply and refine my theoretical knowledge in a practical, real-world setting, bridging the gap between academic concepts and their application in the business world.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
